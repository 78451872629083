import React from 'react';
import styled, { css } from 'styled-components';
import { Button as button } from 'antd';

const Component = styled(button)`
  font-size: 16px;
  padding: 8px 16px;
  ${props =>
    props.size === 'small' &&
    css`
      font-size: 14px;
      padding: 4px 8px;
    `}
  border-radius: 4px;
  height: auto !important;
  background: #fff;
  border: 1px solid #137ac9;
  color: #137ac9;
  font-weight: 500;
  box-shadow: none;
  transition: all 0.2s;

  > svg {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }

  &:hover {
    border: 1px solid #0f5e9a;
    color: #0f5e9a;
  }

  &:active,
  &:focus {
    color: #0a416c;
    border: 1px solid #0a416c;
  }

  ${props =>
    props.disabled &&
    css`
      background: #fff !important;
      color: #afafaf !important;
      border: 1px solid #e1eef8 !important;
    `}
  ${props =>
    props.type === 'ghost' &&
    css`
      border: 1px solid transparent !important;
    `}
  ${props =>
    props.type === 'accent' &&
    css`
      background: #137ac9;
      border: 1px solid #137ac9 !important;
      color: #fff !important;
      ${props.disabled &&
      css`
        background: #e1eef8 !important;
        border: 1px solid #e1eef8 !important;
        color: #afafaf !important;
      `}
      &:hover {
        background: #0f5e9a;
        border: 1px solid #0f5e9a;
      }
      &:active,
      &:focus {
        background: #0a416c;
        border: 1px solid #0a416c;
      }
    `}

  ${props =>
    props.type === 'link' &&
    css`
      padding: 0;
      color: #137ac9 !important;
      background: none !important;
      border: none !important;
      ${props.disabled &&
      css`
        color: #afafaf !important;
      `}
      &:hover {
        color: #0f5e9a !important;
      }
      &:active {
        color: #0a416c !important;
      }
    `}
  ${props =>
    props.type === 'success' &&
    css`
      background: #629553;
      border: 1px solid #629553;
      color: #fff;
      &:hover {
        background: #9ebf95;
      }
      &:focus {
        background: #d8e5d5;
      }
    `}
  ${props =>
    props.type === 'fail' &&
    css`
      background: #cc584c;
      border: 1px solid #cc584c;
      color: #fff;
      &:hover,
      &:focus {
        background: #a7371e !important;
        border: 1px solid #ebebeb !important;
        color: #fff;
      }
    `}
`;

const Button = ({ ...props }) => <Component {...props} />;

Button.Group = Component.Group;
// eslint-disable-next-line no-underscore-dangle
Button.__ANT_BUTTON = true;

/** @component */
export default Button;
